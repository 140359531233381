'use strict';

let $body = $('body');
let $header = $('.page header');
let $features = $body.data('features') ? $body.data('features') : {};
const isPLP = $('body').find('.search-results').length > 0;
let isSticky = $features && $features.enableStickyHeader;

const Cookie = require('./cookie');
const utilities = require('../utils/utilities');

// Get geolocation cookie if it exists
const geolocationCookieString = Cookie.getCookie('geolocation');

/**
 * Sets header position
 */
function setHeader() {
    let $bearBuilder = $('#page-bear-builder');

    if ($bearBuilder.length) {
        return;
    }

    var $scrollPos = $(window).scrollTop();
    // var $bp = window.matchMedia('(max-width: 48em)').matches ? 'mobile' : 'desktop';
    var stickPoint = $header.find('.header-top').outerHeight() + $header.find('.header-ribbon').outerHeight();

    // Toggle utility class on scroll
    if ($scrollPos > 0) {
        $body.addClass('is-scrolled');
    } else {
        $body.removeClass('is-scrolled');
    }

    // Toggle stuck class
    if ($scrollPos >= stickPoint) {
        $body.addClass('is-stuck');
        $header.css('top', '-' + stickPoint + 'px');
        $body.css('padding-top', $header.outerHeight());
    } else {
        $body.removeClass('is-stuck');
        $header.css('top', '0');
        $body.css('padding-top', 0);
    }
}

var allowGeoRecall = true;
var countLocationAttempts = 0;
function getLocation() {
    if (geolocationCookieString) {
        const geoCookie = JSON.parse(geolocationCookieString);
        const position = {
            coords: {
                latitude: geoCookie.latitude,
                longitude: geoCookie.longitude
            }
        };
        getPreferredStore(position);
    } else if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(getPreferredStore, positionError);
    }
}
function positionError() {
    if (allowGeoRecall && countLocationAttempts < 0) {
        countLocationAttempts += 1;
        getLocation();
    }
}
function getPreferredStore(position) {
    var latLng = { latitude: position.coords.latitude, longitude: position.coords.longitude };
    const preferredStoreURL = $('.preferred-store').val();

    // set cookie to prevent multiple location prompts
    Cookie.setCookie('geolocation', JSON.stringify(latLng), 1);

    if (!preferredStoreURL) {
        return false;
    }

    $.ajax({
        url: preferredStoreURL,
        method: 'POST',
        data: latLng,
        success: function (data) {
            if (!data.error) {
                const storeURL = $('.preferred-store-url').val() + '?StoreID=' + data.storeID;
                $('.store-detail').html(`<a href="${storeURL}">${data.storeName}</a>, ${data.storeCity}, ${data.storeStateCode}`);

                if (isPLP) {
                    let $selectStoreLink = $('.select-store-link');

                    $selectStoreLink.each(function () {
                        let $selectStore = $(this).find('.select-store');
                        let $storeName = $(this).find('.store-name');

                        if ($selectStore.length) {
                            $selectStore.hide();
                            $storeName.text(data.storeName).attr('data-storeid', data.storeID).removeClass('d-none');
                        } else {
                            $storeName.text(data.storeName).attr('data-storeid', data.storeID);
                        }
                    });
                }
            }
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
    allowGeoRecall = false;
}

module.exports = function () {
    // Set Sticky if Sticky Feature is Enabled
    if (isSticky) {
        setHeader();
    }

    $('.header-slot').on('click', '.close-button', function (e) {
        e.preventDefault();

        if (isSticky) {
            setHeader();
        }
    });

    $('body').on('click', '.filter-results, .refinement-bar .close', function () {
        if (isSticky) {
            setHeader();
        }
        $('body').toggleClass('filter-active');
    });

    $(document).ready(function () {
        $('.panel-toggle').on('click', function (e) {
            e.preventDefault();
            $('.panel-toggle.active').toggleClass('active btn-primary btn-outline-primary');
            $(this).toggleClass('active btn-primary btn-outline-primary');
            $('.panel.is-active').removeClass('is-active');
            $('.panel').eq($(this).parent().index()).addClass('is-active');
        });
        getLocation();
        openNewSessionPopup();
        recordABTasty();
    });

    $(window).smartresize(function () {
        if (isSticky) {
            setHeader();
        }
    });

    $(window).on('scroll', function () {
        if (isSticky) {
            setHeader();
        }
    });

    function openNewSessionPopup() {
        const $currentPromoBox = $('#newSessionLightbox .mpl-borderbox');
        const hasContent = ($currentPromoBox.children().length > 0 || $currentPromoBox.text().trim() !== '');
        const $newSessionLightbox = $('#newSessionLightbox');

        if (hasContent && Cookie.getCookie('newSessionLightbox') === null) {
            $newSessionLightbox.modal('show');
            Cookie.setCookie('newSessionLightbox', 'true', 1);
        }
    }

    function recordABTasty() {
        var abTests = '',
            abTestsNew = [],
            abTimeout;
        // Get tests recorded on prior pages
        var geturl = utilities.appendParamsToUrl($('#eventCookies').data('geturl'), {
            param: 'abTests'
        });
        $.ajax({
            type: 'GET',
            url: geturl
        }).done(function (response) {
            if (response != 'null' && response.val != 'null') abTests += response.val;
        });
        // Listen for tests on current page
        window.addEventListener('abtasty_executedCampaign', function (e) {
            // All test executions are close together in time. Timeout used to ensure a single, complete send.
            clearTimeout(abTimeout);
            abTestsNew.push(e.detail);
            abTimeout = setTimeout(abSend, 1000);
        });
        // Prepare and send updated test list
        function abSend() {
            var doSend = false;
            for (var i = 0; i < abTestsNew.length; i++) {
                // Only add tests to the list if they aren't already there from a prior page
                if (abTests.indexOf(abTestsNew[i].campaignId + ':' + abTestsNew[i].variationId) == -1) {
                    doSend = true;
                    abTests += abTestsNew[i].campaignId + ':' + abTestsNew[i].variationId + ',';
                }
            }
            // Only send update if something new was added
            if (doSend) {
                var posturl = utilities.appendParamsToUrl($('#eventCookies').data('seturl'), {
                    param: 'abTests',
                    val: abTests
                });
                $.ajax({
                    type: 'POST',
                    url: posturl
                });
            }
        }
    }
};
