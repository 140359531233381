'use strict';

const bootbox = require('bootbox/bootbox.js');

function initGenericPopup() {
    if ($('.popup-trigger').length) {
        let $popUp = $('.popup-html');
        let popUpHtml = $popUp.html();
        $('.popup-trigger').on('click', function (event) {
            event.preventDefault();
            bootbox.dialog({
                message: popUpHtml
            });
        });
    }

    if ($('.disclaimer-pop-trigger').length) {
        let $popUpType1 = $('.disclaimer-html');
        let popUpType1Html = $popUpType1.html();
        $('.disclaimer-pop-trigger').on('click', function (event) {
            event.preventDefault();
            bootbox.dialog({
                message: popUpType1Html,
                className: "skoosherz-popup"
            });
        });
    }

    if ($('.disclaimer-trigger').length) {
        let $popUpType2 = $(this).parent().next('.disclaimer-text');
        let popUpType2Html = $popUpType2.html();
        $('.disclaimer-trigger').on('click', function (event) {
            event.preventDefault();
            bootbox.dialog({
                message: popUpType2Html
            });
        });
    }
}

function videoPopupEvents() {
    if ($('.popup-video-player-wrap').length) {
        $(document).click(function (event) {
            if ($('.modal-video-player').is(':visible')) {
                var clickedElement = $(event.target);
                if (!clickedElement.closest('.popup-video-player-wrap').length) {
                    $('.modal-video-player video').each(function () {
                        // Pause the video
                        this.pause();
                    });
                }
            }
        });

        $('.modal-video-player video').on('play pause', function () {
            if ($('.modal-video-player').is(':hidden')) {
                // Pause the video if the modal is hidden
                this.pause();
            }
        });

        // Select the modal-video-player element
        var modalVideoPlayer = document.querySelector('.modal-video-player');

        // Move the modal-video-player to the end of the body
        if (modalVideoPlayer) {
            document.body.appendChild(modalVideoPlayer);
        }
    }
}

var genericpopup = {
    init: function () {
        initGenericPopup();
        videoPopupEvents();
    }
};

module.exports = genericpopup;
